<template>
  <div class="tw-flex tw-w-full tw-flex-col">
    <div v-for="(accordion, index) in elements" :key="index" class="border-bottom accordion-list tw-py-4">
      <div class="tw-flex tw-items-center tw-justify-between tw-py-2" @click="toggleAccordion(index)">
        <library-title :text="accordion.title" tag="h3"></library-title>
        <div class="tw-cursor-pointer">
          <svg
            v-if="activeIndex === index"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M5 12h14"></path>
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M5 12h14"></path>
            <path d="M12 5v14"></path>
          </svg>
        </div>
      </div>
      <transition
        name="accordion"
        @enter="startTransition"
        @after-enter="setHeightAuto"
        @leave="endTransition"
        style="overflow: hidden; transition: height 0.5s ease-out"
        :style="`height: ${activeIndex === index ? activeHeight : 0}px`"
      >
        <template v-if="accordion.description">
          <library-text
            @click="toggleAccordion(index)"
            v-show="activeIndex === index"
            tag="p"
            :html="accordion.description"
          ></library-text>
        </template>
        <template v-else>
          <div v-show="activeIndex === index">
            <slot name="description"></slot>
          </div>
        </template>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';

export default {
  name: 'LibraryAccordion',
  props: {
    elements: {
      required: false,
      type: Array as PropType<{ title: string; description: string }[]>,
      default: () => [],
    },

    collapsed: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      activeIndex: this.collapsed ? -1 : 0, // collapsed prop'una göre başlangıç durumu
      activeHeight: 0, // Dinamik yüksekliği tutmak için
    };
  },

  methods: {
    toggleAccordion(index: number) {
      if (this.activeIndex === index) {
        this.activeIndex = -1;
      } else {
        this.activeIndex = index;
        this.$nextTick(() => {
          this.updateHeight(index);
        });
      }
    },
    startTransition(el: HTMLElement) {
      el.style.height = '0';
      setTimeout(() => {
        el.style.height = `${el.scrollHeight}px`; // Yüksekliği elemanın scrollHeight'ine göre ayarla
      }, 10);
    },
    setHeightAuto(el: HTMLElement) {
      el.style.height = 'auto'; // Animasyon bittikten sonra auto yap
    },
    endTransition(el: HTMLElement) {
      el.style.height = `${el.scrollHeight}px`; // Kapanırken anlık olarak yüksekliği ayarla
      setTimeout(() => {
        el.style.height = '0';
      }, 10);
    },
    updateHeight(index: number) {
      const element = this.$el.querySelectorAll('.accordion-list')[index] as HTMLElement;
      if (element) {
        this.activeHeight = element.scrollHeight; // Aktif elemanın yüksekliğini hesapla
      }
    },
  },
};
</script>
